<template>
  <div class="status_container">
    <!-- 预约成功 -->
    <div class="content" v-if="isPay == 1">
      <div class="title">
        <van-icon name="passed" color="rgb(13, 190, 13)" size="50px" />
        <span>预约成功</span>
      </div>
      <div class="info">
        <van-cell title="就诊人" :value="orderInfo.name" :border="false" />
        <van-cell title="诊疗卡号" :value="orderInfo.medicalCardNo" :border="false" />
        <van-cell title="流水号" :value="hospitalOrderNum" :border="false" v-if="isPay === 1" />
        <van-cell title="科室" :value="orderInfo.deptName" :border="false" />
        <van-cell title="医生" :value="orderInfo.doctorName" :border="false" />
        <van-cell title="就诊日期" :value="orderInfo.timeSlot" :border="false" />
        <van-cell title="就诊时间" :value="orderInfo.beginTime + '~' + orderInfo.endTime" :border="false" />
        <van-cell title="诊查费" :value="orderInfo.regFee + '元'" :border="false" />
        <van-cell title="就诊指引" :value="orderInfo.position" :border="false" />
        <div class="qrCode">
          <img :src="'data:image/png;base64,' + codeImg">
        </div>
        <div class="tip">生成电子发票十分钟左右后可凭此二维码到自助机内扫描打印电子发票(如需打印发票，请截图保留二维码)</div>
      </div>
      <div class="status-btn">
        <van-button type="info" @click="navRegister">返&nbsp;回&nbsp;首&nbsp;页</van-button>
        <van-button type="primary" @click="navRecord">查&nbsp;看&nbsp;记&nbsp;录</van-button>
      </div>
    </div>
    <!-- 已退款 -->
    <div class="content" v-if="isPay === 2">
      <div class="title">
        <van-icon name="warning-o" color="orange" size="50px" />
        <span>已退款</span>
      </div>
      <div class="info">
        <van-cell title="就诊人" :value="orderInfo.name" :border="false" />
        <van-cell title="诊疗卡号" :value="orderInfo.medicalCardNo" :border="false" />
        <van-cell title="流水号" :value="hospitalOrderNum" :border="false" />
        <van-cell title="温馨提示" :value="orderInfo.remark" :border="false" />
      </div>
      <div class="status-btn">
        <van-button type="info" @click="navRegister">返&nbsp;回&nbsp;首&nbsp;页</van-button>
        <van-button type="primary" @click="navRecord">查&nbsp;看&nbsp;记&nbsp;录</van-button>
      </div>
    </div>
    <!-- 支付中 -->
    <div class="content" v-if="isPay === 7">
      <div class="title">
        <van-icon name="close" color="red" size="50px" />
        <span>支付异常</span>
      </div>
      <div class="info">
        <van-cell title="就诊人" :value="orderInfo.name" :border="false" />
        <van-cell title="诊疗卡号" :value="orderInfo.medicalCardNo" :border="false" />
        <van-cell title="流水号" :value="hospitalOrderNum" :border="false" />
        <van-cell title="温馨提示" :value="orderInfo.remark" :border="false" />
      </div>
      <div class="status-btn" v-if="isPay === 7">
        <van-button type="info" @click="refresh" class="refresh">刷&nbsp;&nbsp;新</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Status",
  data () {
    return {
      orderInfo: {},
      hospitalOrderNum: "",
      isPay: 0,
      id: "",
      receiptNum: '',
      codeImg: ""
    };
  },
  created () {
    this.isPay = parseInt(this.$route.query.isPay);
    this.orderInfo = this.$route.query;
    if (this.isPay === 1) {
      this.getPayStatus();
    }
    this.getPayStatus();
  },
  methods: {
    // 电子发票
    async getInvoice () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });

      const { data: res } = await jsonPost("/wxapp/outpatient/viewInvoice", {
        patCardNo: this.orderInfo.medicalCardNo,
        receiptNum: this.receiptNum,
        visitType: "1"
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.codeImg = res.data.invoiceQRCode
      }
    },
    //查询支付状态
    async getPayStatus () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        outTradeNo: this.orderInfo.outTradeNo || window.localStorage.getItem("medicalCardNo")
      });
      const { data: res } = await formPost(
        "/wxapp/pay/orderRecord/getOrderPayStatus",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.isPay = res.data.isPay;
        this.hospitalOrderNum = res.data.hospitalOrderNum;
        this.id = res.data.id;
        this.orderInfo.remark = res.data.remark;
        this.receiptNum = res.data.receiptNum
        if (res.data.isPay === 1) {
          this.getInvoice()
        } else if (res.data.isPay === 0) {
          this.remark = "支付异常，请到人工窗口办理";
        } else if (res.data.isPay === 2) {
          this.orderInfo.remark = res.data.remark;
        } else if (res.data.isPay === 7) {
          this.remark =
            "系统繁忙或网络异常，业务未能处理完成，请点击“刷新”按钮重试，或到人工窗口办理";
        }
      } else {
        this.$toast(res.msg);
      }
    },
    //刷新重新调起支付
    async recommit () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        id: this.id,
        outTradeNo: this.orderInfo.outTradeNo
      });
      const { data: res } = await formPost("/wxapp/pay/wxpay/recommit", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.isPay = 1;
        this.orderInfo.remark = res.data.remark;
      } else {
        this.$toast(res.msg);
      }
    },
    navRegister () {
      this.$router.push({
        path: "/"
      });
    },
    navRecord () {
      this.$router.push({
        path: "/me/register",
        query: {
          patCardNo: this.orderInfo.medicalCardNo
        }
      });
    },
    refresh () {
      this.recommit();
    }
  }
};
</script>

<style lang='less' scope>
.status_container {
  padding-bottom: 27rem;
}

.status_container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border: 1px solid #f5f5f5;
  box-shadow: darkgrey 10px 10px 30px 5px;
}

.qrCode {
  margin: 0 auto;
  width: 500px;
  height: 500px;

  img {
    width: 100%;
    height: 100%;
  }
}

.tip {
  font-size: 40px;
  padding: 0 30px;
  margin-bottom: 30px;
}

.status_container .content .title {
  position: relative;
  height: 180px;
  font-size: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 120px;
}

.status_container .content .info .van-cell__title,
.status_container .content .info .van-cell__value {
  font-size: 40px;
  line-height: 50px;
}

.status_container .content .info .van-cell__value {
  flex: 2 !important;
}

.status_container .content .status-btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}

.status_container .content .status-btn .van-button {
  font-size: 36px;
}

.status_container .content .status-btn .refresh {
  width: 100%;
}
</style>